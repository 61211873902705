html {
  height: 100%;
}

.back-to-top:hover, .bg-custom, .btn-custom, .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover, .nav-pills > li.active > a {
  color: #fff !important;
  background-color: #12457a !important;
}

.navbar-custom {
  background-color: #fff !important;
}

.btn-white-fill, .text-custom, .btn-white-bordered:hover, .btn-custom:hover, .features-box i, .pricing-column .plan-title {
  color: #12457a !important;
}

.btn-custom {
  border: 2px solid #12457a !important;
}

.btn-custom:focus {
  color: #12457a !important;
  background-color: #fff !important;
  border: 2px solid #12457a !important;
}

.btn-custom:hover {
  color: #fff !important;
  background-color: #757575 !important;
  border: 2px solid #ffffffbf !important;
}

.intro-form input:focus {
  border: 1px solid #12457a;
}

.experience {
  background-color: #f5f5f5;
}

.experience .title {
  color: #777;
}

.experience .features-box {
  color: #12457a !important;
}

.dropdown-menu {
  border-radius: 10px !important;
}

.navbar-custom .navbar-nav li ul li a:focus, .navbar-custom .navbar-nav li ul li a:hover, .navbar-custom .navbar-nav li ul li a:active, .experience .carousel-inner > .item > .item-content blockquote > div.quote:before, .experience .carousel-inner > .item > .item-content blockquote > div.quote:after {
  color: #fff;
}

.experience .carousel-indicators.dark > li {
  border-color: #737373;
}

.experience .carousel-indicators.dark > li.active {
  background-color: #737373;
}

#edit-application-form blockquote, blockquote.quotes {
  background-color: #0000;
  border: 0;
  padding: 30px 90px;
  position: relative;
}

#edit-application-form blockquote:before, #edit-application-form blockquote:after, blockquote.quotes:before, blockquote.quotes:after {
  content: " ";
  color: #3c81ff;
  width: 26px;
  height: 21px;
  font-family: FontAwesome, "Font Awesome 5 Pro";
  display: block;
  position: absolute;
}

#edit-application-form blockquote:before, blockquote.quotes:before {
  content: "";
  top: 30px;
  left: 30px;
}

#edit-application-form blockquote:after, blockquote.quotes:after {
  content: "";
  top: 30px;
  right: 30px;
}

#edit-application-form blockquote, blockquote.quotes {
  padding-top: 0;
  padding-bottom: 0;
}

#edit-application-form blockquote:before, #edit-application-form blockquote:after, blockquote.quotes:before, blockquote.quotes:after {
  font-size: 2em;
}

#edit-application-form blockquote:before, blockquote.quotes:before {
  top: 0;
}

#edit-application-form blockquote:after, blockquote.quotes:after {
  top: initial;
  bottom: 0;
}

.fill-height {
  height: 100%;
}

.row-flex > .col-wide.fill-height > .img-thumbnail.fill-height {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.row-flex > .col-wide.fill-height > .img-thumbnail.fill-height > img {
  flex: 0;
}

#page-apply {
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
}

#page-apply > header, #page-apply > footer {
  flex: 0;
}

#page-apply > main {
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

#page-apply .logo {
  height: 44px;
  min-height: 44px;
}

#page-apply .navbar > .container .navbar-merchant {
  margin-right: -15px;
}

#page-apply > main > pageresponse, #page-apply > main > section:first-child {
  padding-top: 0;
}

#page-apply > main > pageresponse {
  z-index: 1000;
  margin: 11px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#page-apply > main > pageresponse > .row > div > div.alert {
  border-left: 1px solid #ebccd1;
  border-right: 1px solid #ebccd1;
}

#page-apply > main > .hero-sm .carousel-inner.carousel-with-bg > .item > .item-content {
  height: auto;
}

#page-apply .hero-sm .hero-wrapper {
  padding: 15px;
}

#page-apply > footer {
  padding: 5px 0;
}

loading {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

loading > span > i {
  color: red;
  font-size: 25vh !important;
  line-height: 1em !important;
}

loading > span.message {
  text-align: center;
  background-color: #ffffffbf;
  border-radius: 5px;
  padding: 10px;
  font-size: 30px;
  position: absolute;
}

.bootbox.modal-warning > .modal-dialog > .modal-content > .modal-header {
  border-radius: 0;
  margin-top: -25px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15px 25px;
}

.bootbox.modal-warning > .modal-dialog > .modal-content > .modal-footer {
  margin-bottom: -25px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15px 25px;
}

.ribbon {
  z-index: 1;
  text-align: right;
  z-index: 1000;
  width: 75px;
  height: 75px;
  position: absolute;
  top: -5px;
  right: -5px;
  overflow: hidden;
}

.ribbon span {
  color: #555;
  text-transform: uppercase;
  text-align: center;
  width: 100px;
  text-shadow: none;
  background: #eee;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
  transform: rotate(45deg);
  box-shadow: 0 3px 10px -5px #000;
}

.ribbon span:before {
  content: "";
  z-index: -1;
  border: 3px solid #0000;
  border-color: #eee #0000 #0000 #eee;
  position: absolute;
  top: 100%;
  left: 0;
}

.ribbon span:after {
  content: "";
  z-index: -1;
  border: 3px solid #eee;
  border-color: #eee #eee #0000 #0000;
  position: absolute;
  top: 100%;
  right: 0;
}

.ribbon.ribbon-danger span {
  color: #fff;
  background: #d9534f;
}

.ribbon.ribbon-danger span:before {
  border-top-color: #d9534f;
  border-left-color: #d9534f;
}

.ribbon.ribbon-danger span:after {
  border-bottom-color: #d9534f;
  border-right-color: #d9534f;
}

.ribbon.ribbon-warning span {
  color: #fff;
  background: #f0ad4e;
}

.ribbon.ribbon-warning span:before {
  border-top-color: #f0ad4e;
  border-left-color: #f0ad4e;
}

.ribbon.ribbon-warning span:after {
  border-bottom-color: #f0ad4e;
  border-right-color: #f0ad4e;
}

.ribbon.ribbon-info span {
  color: #fff;
  background: #5bc0de;
}

.ribbon.ribbon-info span:before {
  border-top-color: #5bc0de;
  border-left-color: #5bc0de;
}

.ribbon.ribbon-info span:after {
  border-bottom-color: #5bc0de;
  border-right-color: #5bc0de;
}

.ribbon.ribbon-success span {
  color: #fff;
  background: #5cb85c;
}

.ribbon.ribbon-success span:before {
  border-top-color: #5cb85c;
  border-left-color: #5cb85c;
}

.ribbon.ribbon-success span:after {
  border-bottom-color: #5cb85c;
  border-right-color: #5cb85c;
}

.ribbon.ribbon-primary span {
  color: #fff;
  background: #337ab7;
}

.ribbon.ribbon-primary span:before {
  border-top-color: #337ab7;
  border-left-color: #337ab7;
}

.ribbon.ribbon-primary span:after {
  border-bottom-color: #337ab7;
  border-right-color: #337ab7;
}

.ribbon-fixed {
  position: fixed;
  top: 0;
  right: 0;
}

content > .ribbon-fixed {
  top: 25px;
  right: -5px;
}

content > .ribbon-fixed.ribbon-lg {
  top: 24px;
  right: -6px;
}

.ribbon.ribbon-lg {
  z-index: 1000;
  width: 150px;
  height: 150px;
}

.ribbon.ribbon-lg > span {
  width: 200px;
  font-size: 20px;
  line-height: 40px;
  top: 38px;
  right: -42px;
}

.ribbon.ribbon-fixed > span:before, .ribbon.ribbon-fixed > span:after {
  border-width: 3px;
}

body > .ribbon.ribbon-tl {
  inset: 0 auto auto 0;
  transform: rotate(-90deg);
}

body > .ribbon.ribbon-tl > span:before, body > .ribbon.ribbon-tl > span:after {
  display: none;
}

body > .ribbon.ribbon-bl {
  inset: auto auto 0 0;
  transform: rotate(180deg);
}

body > .ribbon.ribbon-bl > span {
  transform: rotate(225deg);
}

body > .ribbon.ribbon-bl > span:before, body > .ribbon.ribbon-bl > span:after {
  display: none;
}

.f-24 {
  font-size: 24px;
}

.f-18 {
  margin: 10px 0;
  font-size: 18px;
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 15%;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-control-prev {
  left: -85px;
}

.carousel-control-next {
  right: -106px;
}

.carousel-control-prev:focus, .carousel-control-next:focus {
  outline-offset: 1px;
  color: #12457a !important;
  background-color: #fff !important;
  outline: 2px solid #12457a !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background: 50% / 100% 100% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(18, 69, 122)' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(18, 69, 122)' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators.dark > li, .carousel-indicators.dark > li.active {
  border-color: #737373;
}

.text-muted {
  color: #707070;
}

.footer-alt .text-muted, .footer a {
  color: #ababab;
}

.footer #dns-button {
  font-size: unset;
  text-align: left;
  color: #ababab;
  background-color: #0000;
  border: 0;
  padding: 0;
  line-height: 22px;
}

.footer-alt .text-muted {
  color: #ababab;
}

span.title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.1;
}

.hero-wrapper h2, .hero-wrapper p {
  color: #fff;
  width: 80%;
  margin: 30px auto 50px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#faqs .nav > li > a:focus, .nav > li > a:hover {
  color: #fff;
  background-color: #737373;
}

.panel-body a, a.contact {
  font-weight: bold;
  text-decoration: underline;
  color: #2d6a9f !important;
}

@media (width <= 320px) {
  .hero-wrapper {
    padding: 50px 0;
  }

  .hero-wrapper h4 {
    margin: 10px auto 20px;
  }

  h1 {
    font-size: 30px;
  }

  .hero-wrapper h1 {
    width: 90%;
    font-size: 30px;
    line-height: 36px;
  }

  .table-responsive th {
    padding: 8px 6px;
    font-size: 13px;
  }

  .table-responsive td {
    padding: 8px 6px;
    font-size: 12px;
  }
}

.footer .list-unstyled > li > a:focus, .footer .list-unstyled > li > button:focus, .footer .list-unstyled > li > button:hover, a:hover {
  outline-offset: 1px;
  color: #12457a !important;
  background-color: #fff !important;
  outline: 2px solid #12457a !important;
}

.annotate {
  color: #366ed4;
  font-style: italic;
}

[role="table"] {
  background-color: #0000;
  display: table;
}

[role="table"] > div[id] {
  text-align: center;
  display: table-caption;
}

[role="table"] [role="row"] {
  display: table-row;
}

[role="table"] [role="cell"], [role="table"] [role="columnheader"] {
  white-space: nowrap;
  text-align: center;
  width: 11em;
  padding: 8px;
  display: table-cell;
}

[role="table"] [role="cell"][firstcell="true"], [role="table"] [role="columnheader"][firstcolumnheader="true"] {
  white-space: nowrap;
  text-align: left;
  width: 800px;
  display: table-cell;
}

[role="table"] [role="columnheader"] {
  border-bottom: thin solid #888;
  width: 12em;
  font-weight: bold;
}

[role="table"] [role="columnheader"] [firstcell="true"] {
  border-bottom: thin solid #888;
  width: 100%;
  font-weight: bold;
}

[role="table"] [role="rowgroup"]:last-child [role="row"]:nth-child(odd) {
  background-color: #ddd;
}

[role="table"] [role="rowgroup"]:last-child [role="row"]:nth-child(2n) {
  background-color: #0000;
}
/*# sourceMappingURL=index.04b6ee65.css.map */
